.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
    justify-content: space-between;

}

.content {
    flex: 1;
    padding-top: 50px; /* 네비게이션 바 높이만큼 패딩을 추가 */
}

.footer {
    background-color: #222;
    width: 100%;
    height: unset;
    color: #fff;
    /* border-top: 1px solid #888; */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0); 
    overflow: hidden;

}
.footer .footer-wrap{display: flex;padding: 0px 50px;
    justify-content: space-between;
    align-items: center;}

.footer .right {display: flex;}
.footer a img{width: 90px;}

/* 전체 스크롤바 트랙 */
body::-webkit-scrollbar {
    width: 5px; /* 스크롤바의 너비 */
    height: 5px; /* 스크롤바의 높이 (수평 스크롤바) */
}

/* 스크롤바 트랙 */
body::-webkit-scrollbar-track {
    background-color: transparent; /* 트랙의 배경색 */
    border-radius: 2px; /* 둥근 모서리 */
}

/* 스크롤바 썸(드래그할 수 있는 부분) */
body::-webkit-scrollbar-thumb {
    background-color: #222; /* 썸의 배경색 */
    border-radius: 2px; /* 둥근 모서리 */
    background-clip: content-box; /* 썸 안쪽으로 테두리 적용 */
    width: 5px;
}

/* 스크롤바 버튼 (위/아래 화살표) */
body::-webkit-scrollbar-button {
    display: none; /* 버튼 숨기기 */
}
#loom-companion-mv3 {
    display: none;
}

.footer_bottom{padding:40px 50px;    align-items: center;
    background-color: #1b1b1b;color: #dbdbdb;border-top: 1px solid #888;display: flex;justify-content: space-between;}

.footer_bottom ul{display: flex;justify-content: right;gap: 40px;}
.footer_bottom ul li{font-size: 15px;font-weight: 400;color: #dbdbdb;}
.footer_bottom p{text-transform: uppercase;}
.copyright span{font-weight: 500;letter-spacing:0.5px;font-size: 13px;}

.footer .footer-wrap .left{display: flex;flex-direction: column;justify-content: left;align-items: start;width: 30vw;}


.footer .footer-wrap .middle{width: 35vw;padding: 40px;}
.footer .footer-wrap .right{width: 15vw;padding: 40px;}

.footer .title{font-size: 13px;color:#c8c8c8;font-weight: 800;letter-spacing: 0.5px;margin-bottom: 20px;}
.logowrap{display: flex;vertical-align: baseline;height: fit-content;align-items: center;word-break: keep-all;}
.logowrap img{margin-right: 30px;justify-content: center;}
.footer .footer-wrap .left img{width: 100px;}
.footer .footer-wrap .left p{font-size: 17px;letter-spacing: 0px;font-weight: 600;display: inline-block;color: #c7c7c7;}
.footer .footer-wrap .left p.slogan{font-size: 23px;letter-spacing: 0.2px;font-weight: 600;margin-left: 30px;display: inline-block;}

.footer .footer-wrap .middle ul{display: flex;justify-content: start;align-items: start;gap: 30px}
.footer .footer-wrap .middle li span{display: block;font-size: 16px;margin-bottom: 8px;}
.footer .footer-wrap .middle li {width: 33%;word-break: keep-all;font-size: 14px;line-height: 24px;color: #dadada;}

.footer .footer-wrap .middle li:last-child{margin-bottom: 0;}
.footer .footer-wrap .middle li  i{font-size: 14px;font-weight: 300;margin-left: 6px;color:#bbbbbb;}
.footer .footer-wrap .right button{
    padding: 10px 3px;width: 90px;margin: 5px; background-color: transparent;border: 1px solid #ddd; color:white;
    font-size: 15px;font-weight: 400;letter-spacing: -1px;width: 200px;cursor: pointer;
}
.footer .footer-wrap .right button i{
    padding-left: 8px;transform: translateY(1px);

}

/* 모바일 시작 */
@media (max-width: 999px) {
    .footer .footer-wrap {
        display: flex;
        padding: 0px 50px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .footer .footer-wrap .left {
        display: flex;        padding: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;        border-bottom: 1px solid #5c5c5c;
    }
    .footer .footer-wrap .middle {
        width: 95vw;
        padding: 0;
    }
    .footer .footer-wrap .middle ul {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0;
        flex-direction: column;
        padding: 20px 10px 15px;
    }
    .footer .footer-wrap .middle ul *{text-align: center!important;}
    .footer .footer-wrap .middle ul p{font-size: 13px;line-height: 1.15;}
    .footer .footer-wrap .middle ul span {font-weight: 400;font-size: 13px;}
    .footer_bottom {padding: 15px;align-items: center;background-color: #1b1b1b;color: #dbdbdb;border-top: 1px solid #888;display: flex;justify-content: center;}
    .footer .footer-wrap .left p {font-size: 13px;
        font-weight: 300;
        opacity: 0.8;
        padding-top: 2px;}
    .footer .footer-wrap .right {        width: 100vw;
        padding: 0px 0px 20px;
        justify-content: center;
    }
    .footer .footer-wrap .left p.slogan {
        font-size: 13px;}
        .footer .footer-wrap .right div{display: flex;justify-content: center;}

        .certification_box h2{font-size: 20px;}
        .footer .footer-wrap .middle li {width: 100%;}
        .logowrap {
            display: flex;vertical-align: baseline;height: -moz-fit-content;height: fit-content;align-items: center;flex-direction: column;
        }
        .logowrap img {
            margin-right: 0px;
        }
        .footer .footer-wrap .left img {width: 70px;margin-bottom: 3px;}
        
        .footer .footer-wrap .left p.slogan {
            margin-left: 0;margin-top: 12px;font-size: 16px;font-weight: 100;border: 1px solid #666;padding: 5px 20px;
        }
        .footer .footer-wrap .middle li:nth-child(1){width: 90vw;}
        .footer .footer-wrap .middle li:nth-child(2){width: 90vw;}
        .footer .footer-wrap .middle li:nth-child(3){width: 90vw;}
        .footer .footer-wrap .middle li{display:flex;flex-wrap:nowrap;justify-content:center;align-items: center;margin-bottom: 5px;}
        .footer .footer-wrap .middle li span{margin-bottom: 0px;margin-right: 18px;width: 40px;text-align: justify;display: none;}
        .footer .footer-wrap .middle li i {
            display: none;
        }
        .footer .title {
            display: none;
        }
        .footer .footer-wrap .right button{
            padding: 10px 3px;width: 170px;margin: 5px; background-color: transparent;border: 1px solid #ddd; color:white;
            font-size: 15px;font-weight: 400;letter-spacing: -1px;
        }
        .copyright span {
            opacity: 0.6;
        }
}
