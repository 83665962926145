
.cls-1 {
    fill: none;
    stroke: #414042;
    stroke-miterlimit: 100;
  }

  /* LoadingSVG.css */
.loading-svg {
    display: flex;
    align-items: center;
    justify-content: center;
   height: 100vh;
    text-align: center;
}
.loading-svg .svg_wrap{
    width: 8VW;
}
.loading-svg path {
    stroke: #222; /* 선 색상 */
    stroke-width:3;
    stroke-dasharray: 300; /* 경로의 길이 */
    stroke-dashoffset:600; /* 경로가 감춰지는 효과 */
    animation: dash 2s ease-in-out infinite alternate;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
