.fixed-buttons {
    position: fixed;
    bottom: 50px;
    right: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

.fixed-buttons.visible {
    opacity: 1;
    visibility: visible;
    z-index: 999!important;
}

.btn {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    background: #222;
    border-radius: 50%;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    overflow: hidden;
    width: 70px;
    height: 70px;
}

.btn .btnSpan {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    border-radius: 100%;
    background: #007BFF;
    z-index: -1;
    transform: translate(-50%, -50%);
    transition: width 0.5s ease, height 0.5s ease;
}

.btn:hover .btnSpan {
    width: 10rem;
    height: 10rem;
}
.btn:nth-child(1){
    border: 1px solid #5e5e5e;
}
.btn:nth-child(2){
    background-color: #fff;
    border: 1px solid #222;
    color:#222;
}

.about-us .btn.kakao .btnSpan{    background: #ffbd09;}
.about-us .btn.naver .btnSpan{    background: #009b08;}
.about-us .btn.kakao,.about-us .btn.naver{font-size: 16px;width: 120px;height: 120px;}