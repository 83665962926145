body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;}

.eng{font-family: "Nunito Sans", sans-serif;  font-weight: 400;}


.mt50{margin-top: 50px;display: block;}
.mb30{margin-bottom: 30px;}
.mb10{margin-bottom: 10px;}

a{text-decoration: none;color:unset;}
p,h2{margin: 0;}
ul{padding: 0;margin: 0;}
.ptclr{color:#007BFF;}

::selection {background-color: #6183aa;color: #fff;}

.side_layout_wrap{display: flex;justify-content: space-between;align-items: center;}

.w1200{width: 1200px;margin: 0 auto;}
.df_center{display: flex;justify-content: center;align-items: center;}
.df_left_column{display: flex;justify-content: start;align-items: center;flex-direction: column;}
.df_left_row{
  display: flex;
  justify-content: start;
  flex-direction: row;
  align-items: end;
  gap: 15px;
}
.df_left_row *{display: inline-block;}
.w1200_left{width: 1200px;margin: 0 auto;text-align: left;}
.error {
  color: red;
  font-size: 12px;
}
.pc_none{display: none!important;}
@media screen and (max-width:999px) {
.m_none{display: none!important;}
.pc_none{display: block!important;}
}

/*-------------------------------------------------------------텍스트 스타일 시작*/
.sub_description{    font-size: 31px;
  font-weight: 400;
  color: #333;
  text-align: left;
  width: 100%;
  padding: 0;
  margin: 0;
  line-height: 43px;
  letter-spacing: -1px;
}

.color_tag{
  font-size: 16px;
  color:#007BFF;
  text-align: left;
  width: 100%;
  margin-bottom: 7px;
font-weight: 600;
text-transform: uppercase;
letter-spacing: 1px;
}
/*-------------------------------------------------------------텍스트 스타일 끝*/

/* 버튼 스타일 정의 시작 */
.rectangle_btnStyle button {
  text-align: center;
  display: inline-block;
    position: relative;
    background-color: #fff;
    font-size: 1rem;
    display: inline-block;
    font-weight: 600;letter-spacing: -0.5px;
    font-family: 'pretendard';
    border:0px;
    cursor: pointer;
    border:2px solid #000;
}
.button--bestia {
	font-size: 1.15rem;
	color: #fff;
	background: none;
	padding: 0;
  cursor: pointer;
  margin: 10px 30px 0px 0px;
  width: fit-content;
  overflow: hidden;
}

.button--bestia .button__bg {
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;

	border-radius: 0.85rem;
	overflow: hidden;
	transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.button--bestia:hover .button__bg {
	transform: scale3d(1.2,1.2,1);
}

.button--bestia .button__bg::before,
.button--bestia .button__bg::after {
	content: '';
	position: absolute;
	background: #000;
}

.button--bestia .button__bg::before {
	width: 110%;
	height: 0;
	padding-bottom: 110%;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	transform: translate3d(-50%,-50%,0) scale3d(0,0,1);
}

.button--bestia:hover .button__bg::before {
	transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
	transform: translate3d(-50%,-50%,0) scale3d(1,1,1);
}

.button--bestia .button__bg::after {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.3s;
}

.button--bestia:hover .button__bg::after {
	opacity: 1;
	transition-duration: 0.01s;
	transition-delay: 0.3s;
}

.button--bestia span {
	display: block;
	position: relative;
	padding: 1.5rem 3rem;
	mix-blend-mode: difference;
}
/* 3칼럼 카드 레이아웃 */
.card{display: flex;flex-direction: row;justify-content: center;width: 1200px;align-items: center;margin: 0 auto;flex-wrap: wrap;margin-top: 50px;margin-bottom: 80px;}
.card div{    flex: 0 0 25%;text-align: center;height: 210px;justify-content: center;padding: 30px;display: flex;border: 0;box-shadow: 3px 3px 30px #ddd;border-radius: 20px;margin: 20px;letter-spacing: -0.5px;}
.card_item{flex-direction: column;word-break: keep-all;border-right: 1px solid #ddd;border-bottom: 1px solid #ddd;}
.card_item:nth-child(4){border-bottom:0px;}
.card_item:nth-child(3) img{    width: 40px;height: unset;}
.card_item:nth-child(5){border-bottom:0px;}
.card_item:nth-child(6){border-bottom:0px;border-right: 0px;}
.card_item h2{margin-bottom: 8px;font-size: 20px;padding: 5px 0;}
.card_item:nth-child(9) h2{margin-bottom: 8px;font-size:18px;}
.card_item img{width: 50px;height: 50px;text-align: center;margin: 0 auto;margin-bottom: 10px;}



/* 레이아웃 제목 left */
.row_wrap_left_title h1{margin: 0;word-break: keep-all;text-align: left;width: 200px;}
.row_wrap_left_title{display: flex;gap: 30px;margin-bottom: 120px;}

.row:nth-child(2n+1){padding: 50px 0;}
.row_wrap_left_title p, 
.row_wrap_left_title li {font-size: 16px;}
.row_wrap_left_title img{border-radius: 20px;margin-bottom: 30px;}
.row_wrap_left_title p{line-height: 1.7;margin-bottom: 20px;}

/* 리스트 스타일  */
.styled-list {
  list-style: none;  /* 기본 목록 스타일 제거 */
  padding: 0;
  margin: 0;
  background-color: #fff;
  padding: 10px 30px;border-radius: 20px;
}

.styled-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* 항목 사이에 간격 추가 */
  font-size: 16px; /* 글자 크기 */
  color: #333; /* 글자 색상 */
  border-bottom:1px solid #ddd;
  padding:20px 0px;
}
.styled-list li:last-child{border: 0;}
.styled-list li::before {
  content: counter(li);
  counter-increment: li;
  color: #222; /* 번호 텍스트 색상 */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: bold;
  border:1px solid #000;
  margin-right: 10px; 
  font-size: 18px; 
}

.video_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  min-height: 70vh; 
  padding-top: 60px;
}
.video_container .video {
  max-width: 100%;
  width: 900px;
  height: 550px;
}



/* 초기화 */
.styled-list {
  counter-reset: li; /* li 항목을 위한 카운터 초기화 */
}

.h100{
  height: 100px;
  display: block;
}
.h50{
  height: 50px;
  display: block;
}
.certification_box{    display: flex;
  flex-direction: column;
  width: 1200px;
  gap: 15px;
  margin-bottom: 100px;}

 .certification_box h1{margin: 0;text-align: left;letter-spacing: -1px;}
.certification_box h2{word-break: keep-all;    font-size: 40px;
  letter-spacing: -2px;}
.certification_box p{    line-height: 1.5;font-size: 20px;letter-spacing: -0.7px;}
/* .certification_box:nth-child(2n-1){transform: translateX(700px);} */
.certification{display: block;justify-content: space-between;align-items: end;width: 1200px;}
.center{text-align: center;}
.bg_img_wrap{background-color: #f4f4f4;width: 100vw;height: 400px;background-attachment : fixed;background-image: url("./img/danger_bg.jpg");}

/* 그리팅 인사말 페이지헤더 */
.greeting .bg_img_wrap{background-color: transparent;width: 100vw;height: 800px;background-attachment : fixed;background-image: url("./img/greeting_top.png");background-position-y: 150px;background-repeat: no-repeat;height: 100vh;    background-size: cover;background-repeat: no-repeat;}
.greeting .bg_img_wrap h1 {
  color: #222;
  text-shadow: unset;
  text-align: left;
letter-spacing: -1.5px;
}
.greeting .bg_img_wrap h2 {
  color: #111;
  font-size: 60px;
  text-shadow: unset;margin-top: 10px;
  text-align: left;
letter-spacing: -2.5px;
}
.greeting .bg_img_wrap {justify-content: flex-start;}

.award .bg_img_wrap{background-color: transparent;width: 100vw;height: 300px;background-attachment : fixed;background-image: url("./img/pageheader_award.png");background-repeat: no-repeat;background-size: contain;}
.award .bg_img_wrap h2 {
  color: #fff;
  font-size: 55px;
  text-shadow: unset;margin-top: 10px;
letter-spacing: -3px;
text-shadow: 2px 2px 8px #000;
font-weight: 600;
}
.award .bg_img_wrap h2 span{color:#a4d8ff;}
.award .bg_img_wrap h1{
  font-size: 25px;
}
.certification_box p span{font-weight: 600;}
.title_box{width: 1200px;margin: 0 auto;padding-top: 130px;}

.bg_img_wrap2{background-color: #f4f4f4;width: 100vw;height: 400px;background-attachment : fixed;background-image: url("./img/danger_bg2.jpg");background-repeat: no-repeat;background-size: cover;}
.bg_img_wrap{display: flex;flex-direction: column;justify-content: center;}
.bg_img_wrap h1{color:#fff;text-shadow: 2px 2px 8px #000;}
.certification_box.welcometext_box{width: 1200px;margin: 0 auto;}

/* 페이지헤더 설정 */
.customer-support .hero_section_bg{background-attachment: fixed;background-image: url(./img/pageheader_bg.jpg);width: 100vw;height: 300px;margin-top:30px;}
.recruitment .hero_section_bg{background-attachment: fixed;background-image: url(./img/pageheader_bg2.jpg);  width: 100vw;height: 300px;margin-top:30px;}
nav.navigation a{word-break: keep-all!important;}

/* 복지 */
.container {max-width: 1200px;margin: 0 auto;padding: 20px;}
h1 {text-align: center;margin-bottom: 40px;}
.benefits-grid {  display: grid;grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));gap: 25px;}
.benefit-category {background-color: #f9f9f9;padding: 20px;border-radius: 8px;box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);}
.benefit-category h2 {font-size: 18px;margin-bottom: 10px;color: #0073e6;}
.benefit-category ul li {margin-bottom: 8px;font-size: 16px;color: #444;}
.benefit-category ul li:before {content: "•";margin-right: 8px;color: #0073e6;}
.benefit-category h2 i {margin-right: 8px;color: #0073e6; /* Adjust color to match your design */
  font-size: 1.2em; /* Adjust size as needed */  vertical-align: middle; /* Adjust alignment */}
.section-label {display: block;font-size: 12px;color: #777;margin-bottom: 4px;text-transform: uppercase;letter-spacing: 0.5px;}

.benefit-category h2 i {
  margin-right: 8px;color: #0073e6; /* Adjust color to match your design */font-size: 1.2em; /* Adjust size as needed */
  vertical-align: middle; /* Align icon vertically with text */}
.heading-tagline {display: block;font-size: 14px;color: #888;margin-bottom: 8px;text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

/* 보도자료 */
.award-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  background-image: url('../src/img/pressLbgImg.jpg');
  background-size: cover;
  background-size: center;
  color: black;
  display:flex;
  justify-content: center;
  align-items: center;
  position: relative;
  isolation: isolate;
}
.award-section::after {
  content: '';
  position: absolute;
  background: rgb(228, 237, 255);
  z-index: -1;
  inset: 0;
  opacity: 0.7;
}

.award-section h2 {
  text-align: center;
  font-size: 28px;
  color: #181818;
  letter-spacing: -1px;
  margin-bottom: 20px;
}

.award-section h3 {
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
  background-color: #0073e6;
  letter-spacing: -1px;
  padding: 5px 15px;
  font-weight: 500;
  display: inline-block;
  margin: 0 auto 10px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}

.award-section p {
  text-align: center;
  font-size: 16px;
  color: #555;
  margin-bottom: 40px;
  letter-spacing: -0.5px;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 30px;
}

.feature-item {
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.feature-item h4 {
  font-size: 18px;
  color: #0073e6;
  margin-bottom: 10px;
  letter-spacing: -1px;
  text-align: center;
  margin-top: 0;
}

.feature-item p {
  font-size: 14px;
  color: #555;margin-bottom: 0;
}
motion.h1 {
  display: inline-block;
}


.slogan_run {
  width: 100%;
  height: 150px;
  overflow: hidden;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-top: 1px solid;
  background: #007BFF;
  color: #fff !important;
  
}
.slogan_run h5 {
  font-size: 60px;width: 100%;
  white-space: nowrap;
  position: absolute;
  animation: scrollText 15s ease-in-out infinite;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 20px;
  text-decoration-thickness: 1px; /* 밑줄 두께 설정 */
  text-decoration-color:#007BFF;
  margin-bottom: 100px;
  color:#fff;
  position: absolute;
  animation: move-right 20s linear infinite;
}
@keyframes move-right {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-100%);
  }
}

.slogan_run h5:hover {
  transition: 0.5s;
}
/* 메인페이지 섹션4 cta */.mainSection4 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 650px;
  background-color: #ebebeb; 
  padding: 40px;
  background: linear-gradient(90deg, #2e4692, #001f31); /* Gradient border colors */
  background-size: 200% 200%;
  animation: gradient-border 3s infinite linear alternate;
  position: relative; /* Ensure positioning for the pseudo-element */
  overflow: hidden; /* Hide any overflow from the pseudo-element */
}

.mainSection4::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: url('../src/img/feature02.jpg') no-repeat center center; /* Replace with your actual image path */
  background-size: cover; 
  background-attachment: fixed;
  opacity: 0.3; 
  z-index: 0; 
  width: 100vw!important;
  height: 100%;
}

.cta-wrapper {
  position: relative;
  width: 1340px;
  margin: 0 auto;
  padding: 0px;
  border-radius: 10px;
  background: linear-gradient(90deg, #ff6f61, #0066b3, #ff6169); /* Gradient border colors */
  background-size: 200% 200%;
  animation: gradient-border 4s infinite linear alternate;
}

@keyframes gradient-border {
  0% {
    background-position: 0% 20%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.cta-container {
  position: relative;
  z-index: 1; /* Ensure the content is above the gradient border */
  text-align: center;
  margin: 0 auto;
  background-color: white;
  padding: 70px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
}

.cta-container h6 {
  font-size: 16px;
  color: #777;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-top: 0;
}

.cta-container h1 {
  font-size: 24px;
  color: #000;
  margin-bottom: 20px;
}

.cta-container p {
  font-size: 40px;
  color: #000;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 30px;
}

.cta-container button {
  font-size: 20px;
  color: #fff;
  background-color: #000; /* Black background for the button */
  padding: 12px 30px;
  border: none;
  border-radius: 50px; /* Rounded button like in the image */
  cursor: pointer;
  transition: background-color 0.3s ease;
  letter-spacing: -1px;
}

.cta-container button:hover {
  background-color: #333; /* Darker on hover */
}



/*현장의 목소리

1.소장 
2. 안전관리자
3. 관리감독자 
4. 협력업체
5. 근로자 

*/
.mainSection1{text-align: center;}
.mainSection1 h2{text-align: center;font-size:40px;word-break: keep-all;letter-spacing: -1px;width: 1200px;margin:100px auto 40px;line-height: 1.4;}
.mainSection2 h1 h6,.mainSection5 h1 h6  {font-size: 20px;margin: 40px 0px 20px;letter-spacing: -0.5px;}
.mainSection2 h1,.mainSection5 h1{margin-top: 0!important;}
.mainSection1 p{color:#444;font-size: 20px;letter-spacing: -1px;line-height: 1.6;}
.slider-container-section1{height: 300px;width: 1200px;margin: 50px auto;margin-top: 50px;margin-bottom: 50px;}
.slider-container-section1 .slide{height: 300px;background-color: #f4f4f4;border-radius: 20px;transform: scale(0.95);}
.slider-container-section1 .slide{height: 300px;}

.roundBorder{display: inline-block;padding: 2px 10px;}
.mainSection2 h1,.mainSection5 h1{font-size: 50px;letter-spacing: -1.5px;}

.top5box_wrap img{width: 70px;}
/* .map .info{background-color: #fff;} */

.top5box_wrap{display: flex;justify-content: center;align-items: center;flex-direction: column;gap: 10px;}
.ccbox{display: flex!important;justify-content: center;align-items: center;    background-color: #f9f9f9!important;border-radius: 8px;box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);}
.coreslide{border-radius: 10px;margin: 0px 20px;}
.h80{height: 80px;}
.mainSection2 h2{font-size: 18px;}

/* 컨텐츠 내부 스타일 */
.section-header {
  font-size: 20px;
  color: white;
  padding: 5px 10px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Specific header colors */
.header-governance {background-color: #7196ce;}

.header-strategy {background-color: #7196ce;}
.header-risk-management {background-color: #3a3a3a; /* Color for 리스크 관리 */}
.header-metrics {background-color: #8a8a8a; /* Color for 반부패 */}
/* List styles */
.section-list {list-style-type: none;padding-left: 0;margin: 0;line-height: 1.8;}
/* List item styling */
.section-list li {font-size: 16px;color: #333;margin-bottom: 5px;    letter-spacing: -1px;}
.header-major-contents {background-color: #20275a; /* Distinct color for major contents */}
.mainSection4 h5{font-size: 15px;font-weight: 400;margin-bottom: 50px;color: #3b3b3b;width:350px;margin: 0 auto;line-height: 1.5;margin-bottom: 40px;word-break: keep-all;}
.cta-container button i{font-size: 15px;transform: translate(5px, -1px);}
.cta-container button{font-weight: 600;}
.sub-content{width:400px;margin: 0 auto;display: flex;justify-content: center;align-items: center;word-break: keep-all;line-height: 1.5;transition: 2s;}

/* Icon styling */
.icon {margin-left: 5px;font-size: 16px; /* Adjust size to fit well with text */display: inline-block;}

.reviewslide .review {width: 450px;font-size:19x;background-color: #fff;padding: 45px;line-height: 1.6;border-radius: 20px;margin-bottom: 30px;position: relative; /* Position relative for the pseudo-element */box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light box shadow for a subtle effect */}

/* Creating the speech bubble tail */
.reviewslide .review::after {content: '';position: absolute;bottom: -20px; /* Position the tail below the box */left: 50%;transform: translateX(-50%);border-width: 10px;border-style: solid;border-color: #fff transparent transparent transparent; /* Tail color matching the box */
}
.reviewslide .ccbox{background-color: #f4f4f4!important;}
.reviewslide .swiper-slide:nth-child(n){background-color: #fdeee3!important;transition: 0.2s;}
.reviewslide .swiper-slide:nth-child(n):hover{background-color: #fcdfcb!important;}
.reviewslide .swiper-slide:nth-child(2n):hover{background-color: #e6d5f5!important;}

.reviewslide .swiper-slide:nth-child(2n){background-color: #eae2f0!important;}
.reviewslide .swiper-slide:nth-child(3n){background-color: #e3f3fd!important;}
.reviewslide .swiper-slide:nth-child(3n):hover{background-color: #c8e9fd!important;}
.reviewslide .top5box_wrap img {border-radius: 50%;width: 70px;height: 70px;order: 1;background: #fff;padding: 15px;}
.reviewslide .top5box_wrap h2 {order: 2; /* Ensure the heading appears below the image */margin-top: 5px;}
table {
  width: 100%;
  border-collapse: separate; /* collapse 대신 separate로 변경 */
  border-spacing: 0; /* 셀 간 간격 조정 */
  margin: 20px 0;
  font-size: 16px;
  text-align: center;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1); /* 그림자 효과 추가 */
  border-radius: 10px; /* 테이블 모서리 둥글게 */
  overflow: hidden; /* border-radius 적용 */
}

th, td,tr {    padding: 25px;
  border: 1px solid #ddd;
  word-break: keep-all;
  background-color: #fff;
  border-right: 0px solid transparent !important;
  border-left: 0px solid transparent !important;
  letter-spacing: -0.5px;}



th[rowspan], td[rowspan] {
  vertical-align: middle;
}



thead th {
  background-color: #007BFF !important; /* 헤더 배경색 */
  color: #fff;
  /* font-weight: bold; */
  border-bottom: 2px solid #222;
}

/* 중대 시민 재해 열의 배경색을 지정 */
td[data-label="중대시민재해"], th[data-label="중대시민재해"] {
  background-color: #c3cee322 !important;
  /* font-weight: bold; */
  color: #333; /* 텍스트 색상 조정 */
}

/* 모바일 뷰에서의 스타일 */
@media screen and (max-width: 768px) {
  table, thead, tbody, th, td, tr {
    display: block;
    width: 100%;
  }

  th, td {
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    padding: 10px;
    border-right: none;
    border-left: none;
  }

  tr {
    border-bottom: 1px solid #ddd;
  }

  thead {
    display: none;
  }

  td {
    position: relative;
    padding-left: 50%;
    border: none;
    border-bottom: 1px solid #ddd;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 10px;
    width: calc(50% - 20px);
    font-weight: bold;
    white-space: nowrap;
    color: #555; /* 설명 텍스트 색상 조정 */
  }

  /* 모바일에서도 배경색을 유지 */
  td[data-label="중대시민재해"] {
    background-color: #ffcccc;
  }
}

.section-list {
  list-style-type: none; /* 기본 리스트 스타일 제거 */
  padding: 0;
}

.section-list li {
  position: relative;
  padding-left: 30px; /* 아이콘과 텍스트 사이의 간격 조정 */
  margin-bottom: 15px;
}

.section-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10px; /* 원이 텍스트와 수직으로 맞도록 조정 */
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #007BFF; /* 파란색 */
}
/* 하늘색 컬러 및 밑줄 스타일 */
.sky-underline {
  color: #007BFF; /* 파란색 */
  text-decoration: underline;
  text-decoration-thickness: 1px; /* 밑줄 두께 줄임 */
}

/* 노란색 형광펜 효과 스타일 */
.highlight-purple {
  background-color: #d9a9fc57; /* 연한 노란색 형광펜 효과 */
  padding: 1px 2px; /* 패딩을 줄여서 효과 최소화 */
}
.highlight-yellow {
  background-color: #fff9c4; /* 연한 노란색 형광펜 효과 */
  padding: 1px 2px; /* 패딩을 줄여서 효과 최소화 */
}
.titlebox{display: flex;flex-direction: row;
margin: 0 auto;width: 100%;justify-content: center;gap: 30px;align-items: center;}
.titlebox img{width: 40px;height: fit-content;}
.major-accident01-container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.major-accident01-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.major-accident01-header-number {
  background-color: #007BFF; /* 파란색 */;
  color: #fff;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.major-accident01-header-title h1 {
  margin-left: 20px;
  font-size: 2.5rem;
  color: #007BFF;
}

.major-accident01-description {
  margin: 0;
  font-size: 20px;
  line-height: 1.6;
}

.major-accident01-description .major-accident01-highlight {background-color: #ffeb3b76;}
.major-accident01-description .blue-text {color: #007BFF;}
.major-accident01-content {display: flex;justify-content: space-between;margin-top: 30px;}
.major-accident01-image-section {flex: 1;display: flex;justify-content: center;align-items: center;}
.major-accident01-speech-bubble {background-color: #007BFF;color: #fff;font-size: 30px;padding: 15px 30px;
  border-radius: 30px;position: relative;text-align: center;}

.major-accident01-speech-bubble::after {content: '';position: absolute;bottom: -28px;left: 50%;
  transform: translateX(-50%);border: 15px solid transparent;border-top-color: #007BFF;}

.major-accident01-details {flex: 2;padding: 0 20px;}

.major-accident01-main-text {font-size: 1.2rem;line-height: 1.8;}

.major-accident01-sub-details {margin-top: 10px;font-size: 1rem;color: #666;letter-spacing: -1px;}
.major-accident01-content{border-top: 1px solid #ddd;padding-top: 40px;}
.major-accident01-footer {    margin-top: 15px;padding: 15px;text-align: center;}
p.major-accident01-main-text{font-size: 22px;letter-spacing: -1px;word-break: keep-all;}
.major-accident01-description p{font-size: 20px;text-align: center;letter-spacing: -1px;}
.major-accident01-footer p{font-size: 27px;letter-spacing: -1.5px;border-top: 2px solid #222;border-bottom: 2px solid #222;
  padding: 10px 0px;}
h1.major-accident01-header{text-align: center;margin-bottom: 30px;width: 100%;display: inline-block;}
.row1.major-accident01{padding-top: 70px;padding-bottom: 80px;}

.row1.major-accident02 {    padding-top: 90px;
  padding-bottom: 100px !important;background-color: #f4f4f4;position: relative;
  overflow: hidden; /* 배경 이미지가 부모 요소를 벗어나지 않도록 처리 */
}
.row1.major-accident02::before {
  content: "";position: absolute;top: 0;left: 0;width: 100%;height: 100%;background: url('img/danger_bg.jpg') no-repeat center center / cover; /* 배경 이미지 설정 */
  opacity: 0.25; /* 이미지의 투명도 조절 (0.0 - 1.0) */
  filter: blur(0px); /* 블러 효과 (값을 조정하여 블러 강도 변경) */
  z-index: 1; /* 그라데이션과 텍스트보다 뒤에 배치 */
}
.row1.major-accident02::after {content: "";position: absolute;top: 0;left: 0;width: 100%;height: 100%;
  background: linear-gradient(to bottom,  rgba(37, 55, 254, 0.05), rgba(112, 124, 255, 0.301)); /* 그라데이션 효과 */
  z-index: 2; /* 텍스트보다 뒤에 배치 */}

.row1.major-accident02 > * {position: relative;z-index: 3; /* 텍스트 및 컨텐츠를 위로 배치 */}
.row1.major-accident02 .inside{background-color: #ffffffe7;padding: 50px 50px;border-radius: 20px;}
.row1.major-accident02 li{font-size: 20px;}
.row h1{letter-spacing: -1px;}
.row1.major-accident03{padding-top: 50px;padding-bottom: 80px;}
.row1.major-accident04 {padding-top: 90px;padding-bottom: 100px !important;background-color: #f4f4f4;position: relative;overflow: hidden; /* 배경 이미지가 부모 요소를 벗어나지 않도록 처리 */}

.row1.major-accident04::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('img/danger_bg2.jpg') no-repeat center center / cover; /* 배경 이미지 설정 */
  opacity: 0.4; /* 이미지의 투명도 조절 (0.0 - 1.0) */
  filter: blur(0px); /* 블러 효과 (값을 조정하여 블러 강도 변경) */
  z-index: 0; /* 그라데이션과 텍스트보다 뒤에 배치 */
}

.row1.major-accident04::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(242, 243, 255, 0.58), rgba(12, 15, 54, 0.3)); /* 그라데이션 효과 */
  z-index: 1; /* 텍스트보다 뒤에 배치 */
}

/* 텍스트와 다른 콘텐츠가 배경과 그라데이션 위에 오도록 설정 */
.row1.major-accident04 > * {
  position: relative;
  z-index: 2; /* 텍스트 및 컨텐츠를 위로 배치 */
}
.row1.major-accident04 li,.row1.major-accident04 p{font-size: 20px;letter-spacing: -1px;}
.row1.major-accident04 p{line-height: 1.7;text-align: center;margin-bottom: 30px;}

/* 사업개요시작 */

.about-us .bg_img_wrap{background-image:url('/src/img/aboutus_bg.png');height: 500px;    background-size: cover;background-color: #000;}
.about-us .bg_img_wrap {
  position: relative;
  height: 500px;
  background-image: url('/src/img/aboutus_bg.png');
  background-size: cover;
  background-color: #000;
  backdrop-filter: blur(10px);
  overflow: hidden;
}

.about-us .bg_img_wrap::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15); /* 투명도 50% */
  z-index: -1;
}



/* 회사 정보 섹션 스타일 */
.info-section {
  max-width: 40%;
  color: #fff;
  text-shadow: unset!important;
}
.aboutus_container{display: flex;justify-content: space-between;align-items: center;}

/* About Us · 회사소개 타이틀 스타일 */
.info-section h2 {
  font-size: 15px!important;
  margin-bottom: 10px;
  letter-spacing: unset!important;
  text-shadow: unset!important;
  text-align: left;
}
.svg_section{width: fit-content;}
.svg_section img{width: 100%;height: 300px;}
/* SAFE D 메인 타이틀 스타일 */
.info-section h1 {
  font-size: 40px!important;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: unset!important;
  text-align: left;
}

/* SAFE D 메인 타이틀의 강조 문자 스타일 */
.info-section h1 span {
  color: #ADD8E6; /* 연한 파란색 */
}

/* 회사 소개 설명 텍스트 스타일 */
.info-section p {
  text-align: left;
  line-height: 1.6;
  font-size: 14px;
  color: #fff;
}


.harfbox_wrap{display: flex;width: 100vw;height: 500px;justify-content: center;align-items: center;}
  .harfbox_wrap .harfbox{width: 50%;height: 100%;display: flex;justify-content: center;align-items: center;background-color: #222;background-size: cover;}
  .harfbox_wrap .harfbox:nth-child(1){background-image: url('./img/harfbox1_bg.jpg');}
  .harfbox_wrap .harfbox:nth-child(2){background-image: url('./img/harfbox2_bg.jpg');background-size: 105%;background-position-x: -15px;background-position-y: -15px;}
  .harfbox_wrap .harfbox img{width: 60%;}

.section2{background-color: #fff;gap: 40px;display: flex;justify-content: center;align-items: center;background-image: url(../src/img/patents_bg@2x.png);background-repeat: no-repeat;background-size: cover;    background-position-y: -10px;flex-direction: row;}
.section2 img{width: 700px;}
.section2 img:nth-child(2){width: 600px;padding-top: 80px;padding-bottom: 80px;}
.blue{width: 100vw;background-color: #0060B7;justify-content: center;align-items: center;display: flex;}
.blue img{width: 1500px;margin-right: 50px;}

.policy section{margin: 40px 0px;}
.policy h2{border-bottom: 2px solid #000;padding-bottom: 10px;margin-bottom: 10px;}
.policy ul li {list-style-type: disc; /* 검은 점 스타일 */margin-left: 20px; /* 리스트 왼쪽 간격 */line-height: 1.5;}
.policy ul{margin: 10px 0px;}
.policy p{line-height: 1.5;}
.policy th,.policy td,.policy tr {padding: 10px;}

/* 999랩탑 반응형 시작 */
@media (max-width: 999px) {
  body{width: 100vw!important;overflow: unset;}
  .fixed-buttons{display: none!important;}
  .crumble-menu{width: 100%!important;}
  .contents,.df_left_row{width: 100vw!important;}
  .certification_box h1 {font-size: 16px;}
.h100 {height: 50px;display: block;}
.color_tag{text-align: center;width: 100%;display: inline-block;}
.certification_box img{display: none;}
.patent .certification_box img{display: block;}
.certification_box.welcometext_box{width: 90vw;margin: 0 auto;margin-bottom: 30px;}
.certification{width: 90vw!important;margin: 0 auto;margin-bottom: 30px;}
.mainSection1 h2 {
  text-align: center;
  font-size: 20px;
  word-break: keep-all;
  letter-spacing: -1px;
  width: 100vw;
  margin: 40px auto 30px;
  line-height: 1.4;
  width: 85%;
}
.mainSection1 p {
  color: #666;
  font-size: 14px;
  letter-spacing: -0.5px;
  width: 70%;
  margin: 0 auto;
  white-space: keep-all;
}
.mainSection2 h1, .mainSection5 h1 {
  font-size: 20px;
  letter-spacing: -1.5px;
}
.mainSection2 h2 {
  font-size: 15px;
}
.heading-tagline {
  font-size: 12px;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
}
.benefit-category ul li {
  margin-bottom:4px;
  font-size: 14px;
  color: #444;
}
.container h1 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 40px;
}
.benefit-category h2 {
  font-size: 15px;
  margin-bottom: 5px;
  color: #0073e6;
}
.press-title {font-size: 1rem;font-weight: bold;margin-bottom: 10px;margin-top: 0;}
.press-description {font-size: 0.8rem;color: #666;}
.press-item {padding: 20px 20px;}
.notice.press-container {
  margin-top: 30px;margin-bottom: 35px;display: block;
}





















@media (max-width: 999px) {


  .section2 {
    background-position-x: -10px;
    background-position-y: -10px;
    flex-direction: column;
    background-size: auto;
    gap: 10px;
    padding-bottom: 40px;
}
.section2 img {
  width: 80%;
  padding-top: 50px;
}
.section2 img:nth-child(2) {
  width: 70%;
  padding-top: 0px;
  padding-bottom: 0px;
}

  .vbox-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .vbox {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
  }
  .vbox-container {
    flex-direction: column;
    align-items: center;
  }

  .vbox {
    width: 90%; /* 모바일에서는 더 넓게 설정 */
    margin: 0 auto;margin-bottom: 30px;
  }
  .vbox h3{font-size: 16px;margin-bottom: 10px;    text-decoration: underline;
    text-decoration-color: #007BFF;
    text-decoration-thickness: 1px;
    text-underline-offset: 7px;
    color: #007bff;margin-bottom: 15px;}
  .vbox p{font-size: 14px;word-break: keep-all;}
}

/* 스타일링된 원 및 선 (이미지 참고) */
.vbox::before, .vbox::after {
  content: '';
  color:#fff;
  position: absolute;
  border-radius: 50%;
  border: 2px solid;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
}

.vbox:nth-child(1)::before {
  border-color: #7196ce;
}

.vbox:nth-child(2)::before {
  border-color: #20275a;
}

.vbox:nth-child(3)::before {
  border-color: #3b52a4;
}



.vbox:nth-child(1)::after {
  background-color: #7196ce;
}

.vbox:nth-child(2)::after {
  background-color:  #20275a;
}

.vbox:nth-child(3)::after {
  background-color: #3b52a4;
}
.vision_wrap .eng_tag_title{text-align: center;font-size: 18px!important;margin-bottom: 65px;}

.info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}
.section-list li {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  line-height: 1.5;
}
.row_wrap_left_title h1,.certification_box h1 {
  margin: 0;
  word-break: keep-all;
  text-align: center;
  width: unset;
  font-size: 18px;
  border-bottom: 2px solid #000;
  margin-bottom: 10px;
  padding-bottom: 3px;
  letter-spacing: -0.5px;
  width: 90vw;
}
.section-header {
  font-size: 15px;
  color: white;
  padding: 2px 5px;
  font-weight: 500;
  margin-bottom: 5px;
}
.row_wrap_left_title p {
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  line-height: 1.5;
}
.row:nth-child(2n+1) {
  padding: 0px 0;
}
.mainSection1 h2 {
  text-align: center;
  font-size: 20px;
  word-break: keep-all;
  letter-spacing: -1px;
  width: 90%;
  margin: 40px auto 15px;
  line-height: 1.4;
}
.mt50 {
  margin-top: 10px;
  display: block;
}
.button--bestia span {
  display: block;
  position: relative;
  padding: 0.5rem 3rem;
  mix-blend-mode: difference;
}
.card-info h1 {
  font-size: 16px !important;
  margin-bottom: 0 !important;
  text-align: center;
}
.card-wrap {
  flex: 0 0 50%;
  height: 200px!important;
  perspective: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.card-info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 10px!important;
  color: #fff;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mainSection2 h1 h6, .mainSection5 h1 h6 {
  font-size: 20px;
  margin: 10px 0px 5px;
  letter-spacing: -0.5px;
}
.award .bg_img_wrap h2 {
  color: #fff;
  font-size: 25px;
  text-shadow: unset;
  margin-top: 10px;
  letter-spacing: -1px;
  text-shadow: 2px 2px 8px #000;
  font-weight: 600;
}
.award .bg_img_wrap h1 {
  font-size: 18px;
}
.award .bg_img_wrap {
  background-color: transparent;
  width: 100vw;
  height: 150px;
  background-attachment: unset;
}
.card-info p {
  font-size: 13px;
  line-height: 1.4;
  margin: 0;
  color: #fff;
  text-align: center;
}
.main-page .h80{display: none!important;}
.cta-container p {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  letter-spacing: -1px;
  margin-bottom: 15px;
}
.cta-container button {
  font-size: 16px;
  color: #fff;
  background-color: #000;
  padding: 8px 25px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  letter-spacing: -1px;
}
.mainSection4 h5 {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 50px;
  color: #666;
  width: 70%;
  margin: 0 auto;
  line-height: 1.4;
  margin-bottom: 15px;
  word-break: keep-all;
}
.cta-container h6 {
  font-size: 14px;
  color: #777;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-top: 0;
}
.cta-container {
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 0 auto;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  width: 100%;
}
.mainSection4 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 320px;
  background-color: #ebebeb;
  padding: 40px;
  background: linear-gradient(90deg, #2e4692, #001f31);
  background-size: 200% 200%;
  animation: gradient-border 3s infinite linear alternate;
  position: relative;
  overflow: hidden;
}
.reviewslide .review {
  width: 75%;
  font-size: 13px;
  background-color: #fff;
  padding: 20px;
  line-height: 1.4;
  border-radius: 20px;
  margin-bottom: 30px;
  position: relative;
  color: #666;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  word-break: keep-all;
}
.mainSection5 h2{font-size: 14px;LETTER-SPACING: -1PX;}
.mainSection5 .swiper {height: unset;}
.mainSection5 .swiper .swiper-slide{height: unset!important;}
.mainSection5 div+.h80{display: none;}
.right-panel {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  height: 350px;
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease;
}
.right-panel_wrap {display: flex;flex-direction: column;justify-content: center;align-items: center;
  width: 40%;height: 350px;background: linear-gradient(to bottom, #2a79ad7c, #033b66a8);filter: blur();
  padding: 0 30px;}
.slide .title {font-size: 25px;margin: 0;animation: fadeInUp 1s ease-in-out forwards;}
.slide .subtitle {font-size: 15px;margin-top: 1rem;width: 60%;line-height: 1.4;word-break: keep-all;margin-bottom: 0px;
  opacity: 0.8 !important;animation: fadeInUp 1s ease-in-out forwards;}
.slide .view-more-btn {
  margin-top: 30px;
  padding: 6px;
  font-size: 15px;
  background-color: rgba(255, 255, 255, 0.0);
  border: 1px solid #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #fff;
  box-sizing: border-box;
  width: 100px;}
.h80 {height: 35px;}
.hover-item {padding: 5px 0;cursor: pointer;font-size: 14px;font-weight: 500;width: 25%;letter-spacing: -1px;
  text-align: center;color: #fff;}

.content-wrap {position: absolute;color: white;z-index: 2;opacity: 0;bottom: 150px;left: 50px;transition: opacity 1s ease-in-out, transform 1s ease-in-out;}
.row_wrap_left_title{display: block;}
.bg_img_wrap2 {background-color: #f4f4f4;width: 100vw;height: 180px;background-attachment: fixed;
  background-repeat: no-repeat;background-size: cover;}
.row,.row1{width: 90vw;margin: 0 auto;}
.row_wrap_left_title {margin-bottom: 30px;}
.styled-list li {display: flex;align-items: center;margin-bottom: 10px;font-size: 14px;color: #333;border-bottom: 1px solid #ddd;
  padding: 10px 0px;word-break: keep-all;}
  .styled-list {
    list-style: none;  /* 기본 목록 스타일 제거 */
    padding: 0;
    margin: 0;
    background-color: #fff;
    padding: 10px 00px;border-radius: 20px;
  }
  .row1:nth-child(6) .section-list li{margin-bottom: 0;}


  .styled-list li::before {
    content: counter(li);
    counter-increment: li;
    color: #222;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-weight: bold;
    border: 1px solid #000;
    margin-right: 8px;
    font-size: 15px;
}
.info p span {
  color: #222;
  font-weight: 500;
  display: block;
  font-size: 15px;
  letter-spacing: -1px;
  margin-bottom: 5px;
}
.info div {
  width: 33%;
  height: 100px;
  margin: 0px 0px 30px;
}
.info p {
  color: #666;
  font-size: 12px;
  line-height: 1.3;
}
.info div {
  width: 33%;
  height: 100px;
  margin: 0px 0px 30px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
}
.info div {
  width: 33%;
  height: 100px;
  margin: 0px 0px 30px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  justify-content: center;
}
.certification {
  display: block;
  justify-content: space-between;
  align-items: end;
  width: 90vw;
}
.loading-svg{display: none!important;}
.button--bestia {margin: 10px 0px 0px 0px;}
.left-panel {/* position: absolute; */display: flex;flex-direction: row;width: 100vw;padding: 0px 20px !important;justify-content: space-between;box-sizing: border-box;background-color: #000;}
.right-panel p {font-size: 20px;max-width: 300px;line-height: 1.4;margin-bottom: 0px;word-break: keep-all;color: white !important;text-align: center;}
.view-button {margin-top: 15px;padding: 10px 25px!important;font-size: 15px;background: transparent;border: 1px solid white;color: white;cursor: pointer;transition: background 0.3s ease, color 0.3s ease;}
.coreslide {border-radius: 0px;margin: 0px;}
.top5box_wrap img{width: 40px;}
.card-info p {font-size: 14px;margin: 0;color: #fff;text-align: center;width: 70%;margin: 0 auto;display: block;margin-top: 10px;}
.ccbox.coreslide{height: 140px!important;}
.mainSection5 h1 h6 {font-size: 15px;margin: 10px 0px 5px;letter-spacing: -0.5px;opacity: 0.7;}
.certification_box{width: 90vw;margin: 0 auto 50px;}
.certification_box p{text-align: center;width: 90vw;margin: 0 auto; }
.card {display: flex;flex-direction: row;justify-content: center;width: 100%;align-items: center;margin: 0 auto;flex-wrap: wrap;margin-top: 50px;margin-bottom: 50px;}
.card div {flex: 0 0 50%;text-align: center;height: 170px;padding: 10px 20px;display: flex;border-bottom: 1px solid #ddd!important;border-right: 0;

}
.card_item h2 {margin-bottom: 8px;font-size: 17px;letter-spacing: -1px;}
.card_item p{font-size: 14px;width: 75%;margin: 0 auto;opacity: 0.8;}
.card_item img {width: 50px;height: 45px;text-align: center;margin: 0 auto;background-color: transparent;margin-bottom: 10px;
  margin-top: 10px;}
  .recruitment .hero_section_bg {background-attachment: fixed;/* background-image: url(http://localhost:3000/static/media/pageheader_bg2.7d6f05f….jpg); */width: 100vw;height: 200px;margin-top: 10px;background-position: top center;background-size: 150%;}
.card_item:nth-child(3) img{width: 60px;}
.customer-support .hero_section_bg {
  /* background-image: url(http://localhost:3000/static/media/pageheader_bg.4ba3bb7….jpg); */
  width: 100vw;height: 200px;margin-top: 10px;background-position: top center; background-size: 150%;}
.features-grid {display: flex;grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));gap: 30px;width: 100%;flex-direction: column;}
.row_wrap_left_title img {width: 100%;}
.reviewslide .top5box_wrap img {border-radius: 50%;width: 50px;height: 50px;order: 1;background: #fff;padding: 0;}
.top5box_wrap {gap: 5px;}
.mainSection2 h1{margin-bottom: 20px;}
.vbox::before, .vbox::after {display: none;}
h1.major-accident01-header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 22px;
  width: 100%;
  display: inline-block;
}
.major-accident01-description p,.row1.major-accident04 p{
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}
.row1.major-accident01 {padding-top: 30px;padding-bottom: 20px;}
p.major-accident01-main-text {
  font-size: 15px;
  letter-spacing: -1px;
  word-break: keep-all;
  line-height: 1.4;
}
.major-accident01-speech-bubble {
  background-color: #007BFF;
  color: #fff;
  font-size: 15px;
  padding: 10px 10px;
  border-radius: 12px;
  position: relative;
  text-align: center;
}
.major-accident01-content {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}
.major-accident01-content {
  display: block;
  justify-content: space-between;
  margin-top: 30px;
}
.major-accident01-image-section {
  margin-bottom: 20px;
}

.major-accident01-footer p {
  font-size: 16px;
 
}
.row h1,.row1 h1 {
  letter-spacing: -1px;
  font-size: 22px;
}
.row1.major-accident02 li,.row1.major-accident04 li, .row1.major-accident04 p{
  font-size: 15px;
}
.row1.major-accident02 .inside,.styled-list {
  background-color: #ffffffe7;
  padding: 20px;
  border-radius: 10px;
  margin: 15px;
}
.major-accident04{width: 100vw;padding: 0 10px;}
.major-accident04 h1{margin-bottom: 20px;}
.row1.major-accident04,.major-accident02{padding-top: 30px;padding-bottom: 30px!important;}
.major-accident02 h1{margin-bottom: 20px;}
.row1.major-accident02 {
  padding-top: 30px;padding-bottom: 30px!important;width: 100vw;
}
.major-accident03 h1{margin-bottom: 20px;}
.greeting .bg_img_wrap {
  background-color: transparent;
  width: 100vw;
  height: 800px;
  background-attachment: fixed;
  /* background-image: url(http://localhost:3000/static/media/greeting_top.c046df0….png); */
  background-position-y: 0;
  background-repeat: no-repeat;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.greeting .bg_img_wrap h2 {
  color: #111;
  font-size: 25px;
  text-shadow: unset;
  margin-top: 10px;
  text-align: center;
  letter-spacing: -1px;
}
.greeting .bg_img_wrap h1 {
  color: #222;
  text-shadow: unset;
  text-align: center;
  letter-spacing: -1px;
}
.title_box {
  width: 100%;
  margin: 0 auto;
  padding-top: 130px;
}
.greeting .certification_box h2 {
  font-size: 20px;
  text-align: center;
}
.greeting .title_box {
  width: 100%;
  margin: 0 auto;
  padding-top: 0;
}
.greeting .certification_box.welcometext_box {
  width: 100vw;
  margin: 0 auto;
  margin-bottom: 0px;
}
.greeting .welcometext_box {
  padding-bottom: 0px;
}
.greeting .certification {
  width: 100vw !important;
  margin: 0 auto;
  margin-bottom: 0px;
  padding-bottom: 100px;
  word-break: keep-all!important;
}
.harfbox_wrap{display: flex;width: 100vw;height:unset;flex-direction: column;}
.harfbox_wrap .harfbox{width: 100%;height: fit-content;padding: 30px 0px;}
.harfbox_wrap .harfbox img{width: 80%;}
.blue{display: none;}

.aboutus_container{flex-direction: column;}

.info-section {
  max-width: unset;
  color: #fff;
  text-shadow: unset !important;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.info-section h2,.info-section h1{text-align: center;}
.info-section p {
  text-align: center;
  line-height: 1.6;
  font-size: 14px;
  color: #fff;
}
.svg_section img {
  width: 100%;
  height: unset;
  margin-top: 20px;
  margin-bottom: 40px;
}
.about-us .bg_img_wrap{height: unset;}
.major-accident05{padding: 50px 10px!important;}
}

@media (max-width: 640px) {
  .card div {flex: 0 0 50%;text-align: center;height: 250px;padding: 10px 20px;display: flex;border-bottom: none !important;border-right: 0;height: 200px;}
  .card div {
    flex: 0 0 40%;
    text-align: center;
    height: 250px;
    padding: 10px 10px;
    display: flex;
    border-bottom: none !important;
    border-right: 0;
    height: 250px;
    justify-content: center;
}
.card div {
  margin: 10px;
}
.award .bg_img_wrap h2 {
  color: #fff;
  font-size: 18px;
  text-shadow: unset;
  margin-top: 10px;
  letter-spacing: -1px;
  text-shadow: 2px 2px 8px #000;
  font-weight: 600;
}
.award .bg_img_wrap h1 {
  font-size: 15px;
}
.slogan_run h5 {font-size: 20px;}
            .slogan_run {                width: 100vw;
                height: 50px;display: none;}
}



.greeting_img {width: 100%;object-fit: cover;background-image: url('../src/img/greeting_bottom.png');background-origin: border-box;background-position-y: 100px;background-repeat: no-repeat;background-size: cover;}
.welcometext_box{padding-bottom:600px;}


.center{display: flex;justify-content: center;flex-direction: column;}
.law3 h1{word-break: keep-all!important;}

@media (max-width: 999px) {
  .law1 h1{margin-top: 50px;}

  
}
@media (max-width: 640px) {
  .image_section_06{width: 90vw;margin-top: 30px;}
  .image_section_07{width: 90vw;}
}

@media (max-width: 425px) {
  .mainSection1 p {
    color: #222;
    font-size: 14px;
    letter-spacing: -0.5px;
    width: 85%;
    margin: 0 auto;
    white-space: keep-all;
    line-height: 1.6;
}
.mainSection4 h5 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.5px;
  margin-bottom: 50px;
  color: #222;
  width: 95%;
  margin: 0 auto;
  line-height: 1.3;
  margin-bottom: 15px;
  word-break: keep-all;
  word-break: keep-all!important;
}
.cta-container button {
  font-size: 14px;
  color: #fff;
  background-color: #000;
  padding: 10px 18px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  letter-spacing: -1px;
  word-break: keep-all;
}
.mainSection2 h2 {
  font-size: 13px;
}
.mainSection1 h2 {
  text-align: center;
  font-size: 18px;
  word-break: keep-all;
  letter-spacing: -1px;
  width: 95%;
  margin: 40px auto 15px;
  line-height: 1.4;
}
.welcometext_box p br{display: none;}
.info{display: none!important;}
.greeting_img{background: none;}

.major-accident05 {
  padding: 0px 10px !important;
}
.row.major-accident05{margin-bottom: 0;margin-top: 50px;}

.law3{margin-bottom: 50px!important;}
.row1.major-accident03 {padding-top: 0px;margin-bottom: 0px;padding-bottom: 30px;}
.major-accident04 p{width: 85%;margin: 0 auto!important;word-break: keep-all;}
.titlebox .development{font-size: 18px;width: 80vw;word-break: keep-all;}
.titlebox img{display: none;}
.video_container .video {
  max-width: unset;
  width: 90vw;
  height: unset;
}
.video_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  min-height: unset;
  padding-top: 30px;
}
.card_item img {
  width: 40px;
  height: 35px;
  text-align: center;
  margin: 0 auto;
  background-color: transparent;
  margin-bottom: 5px;
  margin-top: 5px;
}
.card_item p {
  font-size: 15px;
  width: 90%;
  margin: 0 auto;
  opacity: 0.9;
  line-height: 1.4;
}
.card_item h2 {
  margin-bottom: 3px;
  font-size: 17px;
  letter-spacing: -1px;
}
.card_item:nth-child(3) img {
  width: 43px;
}
.card_item:nth-child(9) h2 {
  margin-bottom: 8px;
  font-size: 16px;
}
td {position: relative;padding-left: 50%;border: none;border-bottom: 1px solid #ddd;font-size: 14px;}

}
.law1 .inside{display: flex;justify-content: center!important;width: 90vw;align-items: center;    margin: 0 auto;gap: 30px;margin-bottom: 0px;}
.image_section_06{width: 45vw;margin-top: 30px;}
.image_section_07{width:42vw;}


@media (max-width: 999px) {
  .law1 .inside{display: flex;justify-content: center!important;width: 90vw;align-items: center;margin: 0 auto;gap: 30px;margin-bottom: 0px;flex-direction: column;}
  .law1 .inside img{width: 100%;}
  .image_section_06_m{margin-bottom: 0px!important;margin-top: 20px;}
  .image_section_07_m{width: 75vw!important;}
}
@media (max-width: 640px) {
  .image_section_07_m{width: 95vw!important;}
}