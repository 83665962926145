/* Example styles */
body {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

/* .contents h1 {
  text-align: center;
  margin-top: 60px;
} */


ul li{list-style: none;}

body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}