/* src/components/Modal.css */

.modal-container *{    
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif!important;
    letter-spacing: -0.2px;
}

.modal-container {
    background-color: #fff; /* 기본 배경 색상 변경 */
    border-radius: 20px;
    overflow: hidden;
  }
  
  .modal-content {
    padding: 40px;
    width: 600px;overflow-y: scroll; 
    height: 55vh;
  
  }
  
  .modal-title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .modal-body {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .modal-close-button {
    background-color: #007bff; /* 버튼 배경 색상 */
    color: white;
    border-radius: 15px;
  }
  
  .modal-close-button:hover {
    background-color: #0056b3; /* 버튼 호버 색상 */
  }
  
  /* 스크롤바 스타일 */
.modal-content::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
  }
  
  .modal-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3); /* 스크롤바 색상 */
    border-radius: 10px; /* 스크롤바 모서리 둥글게 */
  }
  
  .modal-content::-webkit-scrollbar-track {
    background-color: transparent; /* 스크롤바 트랙 색상 */
  }