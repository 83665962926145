.navigation_wrap{
    width: 100vw;
    background: transparent;
    position: fixed;
    top: 0;
    z-index: 900; /* Ensure it stays on top */
    height: 90px;
}
.navigation_wrap .navigation {
    position: fixed;
    width: 100%;
    top: 0;
    background: transparent;
    transition: background-color 0.3s;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    padding: 20px 0;
}

.navigation_wrap.scrolled {background: white;border-bottom: 1px solid #ddd;}

.navigation.scrolled {background: white;  /*box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); !* Optional shadow for better visibility *!*/
    border-bottom: 1px solid #ddd;}
.navigation h1{
    width: 100px;
    margin: 0;
    margin-left: 50px;
}
.navigation h1 img{
    width: 100px;
}
.navigation ul {
    gap: 50px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    width: 50vw;
}

.navigation li {
    display: inline;
}

.navigation a {
    text-decoration: none;
    color: black;
    font-size: 19px;
    font-weight:500;
    letter-spacing:-0.2px;
}
/* Navigation.css */
.navigation_wrap {
    position: fixed;
    width: 100vw;
    z-index: 50;  
    box-sizing: border-box;
    transition: background 0.5s ease, backdrop-filter 0.5s ease;
}

.navigation_wrap.scrolled {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-item {
    color: white;
    margin: 0 10px;
    display: inline-block;
}
.navigation .right{
    display: flex;gap: 20px;align-items: center;    max-width: 250px;
    margin-right: 50px;
    min-width: 250px;
}

.navigation .ham {
    cursor: pointer;
}

.navigation .ham i {
    font-size: 24px;
    color: #333;
}

.navigation .inquiry-button {
    border-radius: 20px;
    background-color: #007BFF;
    color: white;display: flex;word-break: keep-all;
    padding: 10px 20px;flex-wrap: nowrap;justify-content: center;align-items: center;
    text-decoration: none;
}
.navigation .inquiry-button i{font-size: 17px;transform: translateY(-0.5px);
    margin-left: 5px;}
.navigation .inquiry-button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .navigation ul {
        display: none;
    }

    .navigation .ham {
        display: block;
    }
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    backdrop-filter: blur(5px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.overlay-content {
    text-align: center;
    color: white;
}

.overlay-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.overlay-content ul li {
    padding: 5px 10px;
    margin: 35px 0;
    border-bottom:1px solid #666;
}

.overlay-content ul li a {
    text-decoration: none;
    color: white;
}

.main-page.navigation_wrap span.menu_text{
    color:#fff;
}

.main-page.navigation_wrap .ham i::before{
    color:#fff;
}

.navigation_wrap.scrolled span.menu_text{
    color:#000;
}

.navigation_wrap.scrolled .ham i::before{
    color:#000;
}

.close-btn::after{
    content: 'Close';
    position: absolute;
    font-size: 10px;
    word-break: keep-all;
    top: 28px;
    opacity: 0.8;
    left: 13px;
}

.close-btn {
    position: absolute;
    top: 30px;
    right: 40px;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    z-index: 9999;
    cursor: pointer;
    width: 50px;
    height: unset;
    cursor: pointer;
}

.close-icon {
    stroke: white;
    stroke-width: 1;
    stroke-linecap: round;
    transition: transform 0.3s ease-in-out;
}

.close-btn:hover .close-icon {
    transform: rotate(90deg);
}

.navigation_wrap.main-page ul li a.main-page-link {
    color: #fff;
    text-shadow:#3333334d 2px 2px 13px;
    font-weight: 600;
}

.navigation_wrap.main-page.scrolled ul li a.main-page-link {
    color: #000;
}

.navigation_wrap.main-page.scrolled img {
    content: url('../img/logo.png'); /* 스크롤 시 기본 로고 이미지 */
}

.navigation_wrap img {
    transition: content 0.3s ease;
}

.navigation_wrap ul li a {
    transition: color 0.3s ease-in-out;
}
span.menu_text{
    font-size: 11px;
    text-anchor: middle;
    transform: translateY(-6px);
    display: inline-block;
    letter-spacing: 1.5px;
    margin-right: 8px;
}

/*서브메뉴 수정*/
/* Navigation.css */
.navigation_wrap {
    /* your existing styles */
}

.navigation {
    position: relative;
}

.navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.navigation ul li {
    position: relative;
}

.navigation ul li .submenu {
    position: absolute;
    top: 100%;
    width: 150px;
    left: 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
    flex-direction: column;
    z-index: 1000;
    border-radius: 10px;
    padding: 7px;
}

.navigation ul li:hover .submenu {
    display: flex;
}

.submenu a {
    padding: 12px 15px;
    text-decoration: none;
    color: #333;
    font-size: 16px;
    border-radius: 10px;
}

.submenu a:hover {
    background-color: #f0f0f0;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    overflow: hidden;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    backdrop-filter: blur(10px);
    transition: opacity 0.3s ease, transform 0.5s ease;
}

.overlay-content {
    text-align: center;
    color: #fff;
    width: 50vw;
    padding: 40px;
}

.overlay-links {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.overlay-column {
    text-align: left;
}

.overlay-column h3 {
    font-size: 35px;
    font-weight: 400;
    margin-bottom: 20px;
}

.overlay-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.overlay-column ul li {
    padding: 10px 0;
    margin: 10px 0;
}

.overlay-column ul li a {
    font-size: 18px;
    word-break: keep-all;
    text-decoration: none;
    color: white;
    opacity: 0.8;
    font-weight: 200;
    transition: color 0.2s ease-in-out;
}

.overlay-column ul li a:hover {
    color: #007BFF;
    opacity: 1;
}

.close-btn::after {
    content: 'Close';
    position: absolute;
    font-size: 10px;
    word-break: keep-all;
    top: 28px;
    opacity: 0.8;
    left: 13px;
}

.close-btn {
    position: absolute;
    top: 30px;
    right: 40px;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    z-index: 9999;
    cursor: pointer;
    width: 50px;
    height: unset;
    cursor: pointer;
}

.close-icon {
    stroke: white;
    stroke-width: 1;
    stroke-linecap: round;
    transition: transform 0.3s ease-in-out;
}

.close-btn:hover .close-icon {
    transform: rotate(90deg);
}

span.menu_text {
    font-size: 11px;
    text-anchor: middle;
    transform: translateY(-6px);
    display: inline-block;
    letter-spacing: 1.5px;
    margin-right: 8px;
}

@keyframes expandMask {
    0% {
        clip-path: circle(0% at 50% 50%);
    }
    100% {
        clip-path: circle(150% at 50% 50%);
    }
}
@media (max-width: 999px) {
.navigation_wrap{height: 50px;width: 100vw;}
.navigation_wrap .navigation{width: 100vw;    padding: 10px 0;}
.navigation h1 {width: unset;margin: 0;margin-left: 10px;display: flex;align-items: center;}
.navigation .right{display: flex;align-items: center;max-width: unset;margin-right: unset;min-width: unset;margin-right: 15px;}
    .hero-section h1 {font-size: 20px;}
        .nav-link{font-size: 14px;}
        .sub_description {font-size: 15px;font-weight: 400;color: #333;text-align: center;width: 100%;padding: 0;margin: 0;line-height: 21px;letter-spacing: -1px;width: 90vw;margin: 0 auto;}
        .certification_box p {line-height: 1.5;font-size: 13px;}
        .hero-section {text-align: center;padding: 40px 0 20px;}
        .bg_img_wrap {background-color: #f4f4f4;width: 100vw;height: 150px;background-attachment: fixed;
            /* background-image: url(http://localhost:3000/static/media/danger_bg.a4adcf8….jpg); */            background-size:120%;background-position: center;}
        .h50 {height: 20px;display: block;}
        span.menu_text {font-size: 7px;text-anchor: middle;transform: translateY(-6px);display: inline-block;letter-spacing: 1.5px;margin-right: 4px;}
.app-container{width: unset;}
.navigation a {font-size: 12px;}
.navigation h1 img {width: 60px;}

/* 서브메뉴 항목 텍스트 크기 */
.submenu a {font-size: 14px;}

/* 오버레이 내 텍스트 크기 */
.overlay-column ul li a {font-size: 14px;}

/* 서브메뉴와 네비게이션 간격 조정 */
.navigation ul {display: none;}
/* 인콰이어리 버튼 텍스트 크기 */
.navigation .inquiry-button {display: none;}
/* 햄버거 메뉴 아이콘 크기 */
.navigation .ham i {font-size: 22px;}
.hero-section h1{font-size: 20px;}
.app-container{display: unset;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* {
box-sizing: border-box; /* 패딩과 마진이 포함되도록 설정 */
}
.w1200{width: 100%;}
.overlay-links {display: flex;justify-content: space-around;width: 100%;flex-direction: column;}
.overlay-content {
    text-align: center;
    color: #fff;
    width: 85vw;
    padding: 40px;
}
.overlay-column h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: -1px;
}
.overlay-column ul li {
    padding: 3px 0;
    margin: 2px 0;
}
.overlay-column{margin-bottom: 20px;}
.side_layout_wrap {display: flex;justify-content: space-between;align-items: center;flex-direction: column;}
}