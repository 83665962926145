.crumble-menu *{color: #222!important;}
.crumble-menu .menu-item {
    font-size: 14px;
    color: black;
    text-decoration: none; 
}

.crumble-menu .menu-item.active {
    color: rgb(0, 0, 0);
}
